import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { rootStyles } from '../../style/shared'
// import { navbarLinks } from '../../routes/navbar.links'
import Flex from '../../components/Flex'
import { useCategoriesQuery } from '../../pages/home/api/queries'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentCategory } from '../../redux/features/categorySlice'
import SimpleEmptyText from '../../components/Empty/SimpleEmptyText'
import { CATEGORIE_PAGE } from '../../routes/navigation_routes'

const NavbarFluidPane = styled.nav`
    color: ${rootStyles.colors.quaternary};
    padding: ${rootStyles.padding.md}px;
    box-shadow:  rgba(9, 30, 66, 0.13) 0px 0px 0px 1px inset;
    // width: 100%;
    overflow-x: auto;
    position: sticky;
    top: -2px;
    background-color: ${rootStyles.colors.appBgColor};
    z-index: 2;
     &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    };
    &::-webkit-scrollbar-track {
        -webkit-box-shadow:  0 0 2px ${rootStyles.colors.action}; 
        border-radius: 10px;
    };
    &::-webkit-scrollbar-thumb {
        height: 5px;
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 4px ${rootStyles.colors.action};
    };

    @media screen and (max-width: 480px) {
        position: fixed;
        bottom: 0px !important;
        top: initial;
        left: 0;
        width: 100%;
        background-color: ${rootStyles.colors.appBgColor};
        padding: 5px ${rootStyles.padding.md}px;
    }
    `
const NavbarPane = styled.div`
    max-width: ${rootStyles.width.childrenMaxWidth}px;
    margin: 0 auto;
    min-width: 900px;
    overflow: hidden !important;
   
`
const NavlinkPane = styled(NavLink)`
    color: ${rootStyles.colors.quaternary};
    text-decoration: none;
    text-transform: uppercase;
    font-size: ${rootStyles.fontSize.sm}px;
    display: inline-block;
    font-weight: 400;
    font-family: Helvetica;
    transition: all 300ms ease;
    box-sizing: border-box;
    padding: ${rootStyles.padding.sm - 4}px;
    // flex: 1;

    background:
    linear-gradient(
      to right,
      rgba(100, 200, 200, 1),
      rgba(100, 200, 200, 1)
    ),
    linear-gradient(
      to right,
      ${rootStyles.colors.action},
      ${rootStyles.colors.action},
      ${rootStyles.colors.action}
    );
    background-size: ${({ isactive }) => isactive === "true" ? "0 3px, 100% 3px" : "0% 3px, 0 3px"};
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;

    &:hover {
        opacity: .7;
        background-size: 0 3px, 100% 3px;
        color: ${rootStyles.colors.avisColor}
    };

    @media (max-width: 500px) {
        font-size: ${rootStyles.fontSize.sm - 2}px;
    }
`

const Navbar = () => {  
    const { currentCategory } = useSelector(state => state.categories)
    const { data = [], isError, isLoading } = useCategoriesQuery()
    const dispatch = useDispatch()

    if (isLoading) { 
        return (
            <NavbarFluidPane>
                <NavbarPane>
                    Loading...
                </NavbarPane>
            </NavbarFluidPane>
        )
    }

    return (
        <>
            <NavbarFluidPane>
                <NavbarPane>
                    {
                        isError ? <SimpleEmptyText message={"Une erreur est survenue lors de la récuperation des catégories"} /> : <Flex justify="space-between">
                            {
                                data.length ? data.map(({ id, label, slug }) => {
                                    return <NavlinkPane
                                        onClick={() => dispatch(setCurrentCategory({ id, label }))}
                                        key={id}
                                        isactive={`${currentCategory.id === id}`}
                                        to={`${CATEGORIE_PAGE}/${slug}`}>
                                        {label}
                                    </NavlinkPane>
                                }) : <SimpleEmptyText message={"Pas encore de catégories"} />
                            }
                        </Flex>
                    }
                </NavbarPane>
            </NavbarFluidPane>
        </>
    )
}

export default Navbar