import React from "react"
import FadeTransition from "../../../components/FadeInTransition"
import LoginForm from "../../../components/Login/LoginForm"
import { LoginProvider } from "../../../features/auth/Provider"
import * as Styled from "./styles"
import Logo from "../../../components/Logo"
import { AUTH_PAGE, RESERVATIONS_PAGE, SIGNUP_PAGE_PATH } from "../../../routes/navigation_routes"
import { useSigninMutation } from "../../../features/auth/api/mutations"
import { setCurrentUser } from "../../../redux/features/userslice"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { errorMsg } from "../../../helpers/errorMsg"

const LoginPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loginMutation, { isLoading }] = useSigninMutation()
    const onSubmit = (data) => {
        loginMutation({ user: data })
            .unwrap()
            .then(res => {
                dispatch(setCurrentUser(res))
                return navigate(AUTH_PAGE + RESERVATIONS_PAGE)
            }).catch(err => {
                errorMsg(err.data.message)
            })
    }
    return (
        <FadeTransition>
            <Styled.LoginPane>
                <LoginProvider>
                    <Styled.FormContainerPane>
                        <Styled.LogoContainerPane>
                            <Styled.AuthTitle>Connexion</Styled.AuthTitle>
                            <Logo />
                        </Styled.LogoContainerPane>
                        <LoginForm isLoading={isLoading} onLogin={onSubmit} />
                        <Styled.AltLink to={SIGNUP_PAGE_PATH}>Vous n'avez pas de compte? Inscrivez-vous.</Styled.AltLink>
                    </Styled.FormContainerPane>
                </LoginProvider>
            </Styled.LoginPane>
        </FadeTransition>
    )
}

export default LoginPage