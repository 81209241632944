import Accordion from "react-bootstrap/Accordion";
import styled from "styled-components";
import { rootStyles } from "../style/shared";

const HeaderTitlePane = styled.h6`
    font-size: ${rootStyles.fontSize.md}px;
    margin-bottom: 0px;

    @media screen and (max-width: 600px){
        font-size: ${rootStyles.fontSize.sm}px
    }
`

const AccordionItem = ({ eventKey, accordionTitle, children }) => {
    return (
        <Accordion.Item eventKey={eventKey}>
            <Accordion.Header>
                <HeaderTitlePane>{accordionTitle}</HeaderTitlePane>
            </Accordion.Header>
            <Accordion.Body>
                {children}
            </Accordion.Body>
        </Accordion.Item>
    )
}

export default AccordionItem