import React, { Suspense } from 'react'
import Header from './header/Header'
import Footer from './footer/Footer'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { rootStyles } from '../style/shared'
import PageLoading from '../features/reservation/components/PageLoading'
import Navbar from './navbar/Navbar'

export const MainContaintPane = styled.main`
background-color: ${rootStyles.colors.appBgColor}
`

const AppShell = () => {
    return <Suspense fallback={<PageLoading />} >
        <Header />
        <Navbar />
        <MainContaintPane>
            <Outlet />
        </MainContaintPane>
        <Footer />
    </Suspense>
}

export default AppShell