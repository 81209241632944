import {
  RouterProvider,
} from "react-router-dom";
import { router } from "./routes/navigation_paths";
import { Toaster } from "react-hot-toast";
import AuthenticationProvider from "./context/Authentication/AuthenticationProvider";
import CacheBuster from "./CashBuster";
import Roots from "./Roots";

function App() {
  return (
    <>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <>
              <AuthenticationProvider>
                <Roots />
                <Toaster
                  position="top-center"
                  reverseOrder={false}
                  gutter={8}
                  containerClassName=""
                  containerStyle={{}}
                  toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                      background: '#363636',
                      color: '#fff',
                    },

                    // Default options for specific types
                    success: {
                      duration: 3000,
                      theme: {
                        primary: 'green',
                        secondary: 'black',
                      },
                    },
                  }}
                />
              </AuthenticationProvider>
            </>
          );
        }}
      </CacheBuster>
    </>
  );
}

export default App;
