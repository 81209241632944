import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Flex from '../Flex'
import Input from '../Input'
import Div from '../Div'
import Button from '../Button'
import { rootStyles } from '../../style/shared'

const LoginForm = ({ isLoading, onLogin }) => {
    const { handleSubmit, register, formState: { errors, isSubmitting } } = useFormContext()

    const onSubmit = (values) => {
        onLogin(values)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex align={"center"} gap={10} flex={1} wrap={"wrap"}>
                <Input
                    label={"Email"}
                    placeholder={"Email"}
                    type={"email"}
                    error={errors.login?.message}
                    register={{ ...register("login") }}
                />
                <Input
                    isPassword
                    label={"Mot de passe"}
                    placeholder={"Mot de passe"}
                    type={"password"}
                    error={errors.password?.message}
                    register={{ ...register("password", { required: "Mot de passe obligatoire" }) }}
                />
            </Flex>
            <Div mt={20} mb={20}>
                <Button
                    disabled={isLoading}
                    width={100}
                    letterSpacing={3}
                    fontSize={rootStyles.fontSize.sm}
                    fontWeight={600}
                    textTransform="uppercase"
                    paddingVertical={rootStyles.padding.md}
                    borderWidth={2}
                    borderStyle="solid"
                    borderColor={rootStyles.colors.action}
                    borderRadius={rootStyles.borderRadius.xl}
                    backgroundColor={rootStyles.colors.action}
                    color={rootStyles.colors.primary}
                // hoverBackgroundColor={rootStyles.colors.primary}
                // hoverColor={rootStyles.colors.action}
                // width={100}
                >
                    {
                        isLoading ? "Connexion en cours..." : "Connexion"
                    }
                </Button>
            </Div>
        </form >
    )
}

export default LoginForm