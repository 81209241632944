import React from 'react'
import styled from 'styled-components'
import MainContent from './MainContent'
// import Logo from './Logo'
import { rootStyles } from '../style/shared'

const ErrorMessageText = styled.div`
    font-size: ${rootStyles.fontSize.md}px;
    margin-top: 20px;
`
const Error404Pane = styled.span`
    font-weight: 900;
    font-size: ${rootStyles.fontSize.lg}px

`
const ErrorDescription = styled.p`
    margin-top: 20px;
    font-size: ${rootStyles.fontSize.md}px;
`

const Page404 = () => {
    return (
        <MainContent>
            {/* <Logo /> */}
            <ErrorMessageText><Error404Pane>404</Error404Pane>, C'est une erreur</ErrorMessageText>
            <ErrorDescription>
                La page demandée n'est pas trouvée</ErrorDescription>
        </MainContent>
    )
}

export default Page404