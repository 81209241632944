import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import _ from "lodash"

import MainContent from '../../../components/MainContent'
import { rootStyles } from '../../../style/shared'
import Flex from '../../../components/Flex';

import { HiOutlineLocationMarker } from "react-icons/hi"
import { AiOutlineStar } from "react-icons/ai"
import Div from '../../../components/Div';
import ReservationFeature from '../../../features/reservation/ReservationFeature'
import Dot from '../../../components/Dot'
import Button from '../../../components/Button'
import SignInAndRedirectToReservation from './components/SignInAndRedirectToReservation'
import SignUpAndRedirectToReservation from './components/SignUpAndRedirectToReservation'
import FadeInSpring from '../../../components/FadeInSpring'
import GoBack from '../../../components/GoBack'
import useScrollTop from '../../../utils/scrollToTop'
import { useDetailsSalonQuery } from '../api/queries'
import { useParams } from 'react-router-dom'
import { setCurrentSalon } from '../../../redux/features/salonSlice'

export const ReservationTitle = styled.h2`
    font-size: ${rootStyles.fontSize.lg}px;
    font-weight: 500;
    // color: ${rootStyles.colors.primary};
       @media screen and (max-width: 768px) {
        font-size: ${rootStyles.fontSize.md}px;
    }
`
export const AdressePane = styled.p`
    text-decoration: underline;
    font-size: ${rootStyles.fontSize.md}px;
    font-weight: 300;

    @media screen and (max-width: 768px) {
        font-size: ${rootStyles.fontSize.md - 3}px;
    }
`
export const SpanTextPane = styled.span`
    font-size: ${rootStyles.fontSize.md}px;
    box-sizing: inherit;
`
export const PrestationTitle = styled.h3`
    font-size: ${rootStyles.fontSize.md}px;
    font-weight: 500;

       @media screen and (max-width: 768px) {
        font-size: ${rootStyles.fontSize.md - 3}px;
    }
`
export const SelectedServicesPane = styled.div`
    margin-top: 40px;
`
export const ReservationsPane = styled.div`
    margin-top: 20px;
`
export const AuthenticationPane = styled.div`
    margin-top: 40px;
    padding: ${rootStyles.padding.lg}px ${rootStyles.padding.md}px;
    background-color: ${rootStyles.colors.primary};
    border-radius: 8px;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
`
const AuthTitlePane = styled.p`
    color: #000;
    text-align: center;
    font-size: ${rootStyles.fontSize.md}px
`
const LinePane = styled.div`
    width: 100%;
    height: 1px;
    border-radius: 10px;
    background-color: #869098;
    postition: relative;
`
const TextPane = styled.span`
    display: inline-block;
    text-transform: uppercase;
    background-color: ${rootStyles.colors.primary};
    // background-color: red;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-weight: 700;
    font-size: ${rootStyles.fontSize.sm - 2}px;
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    // box-shadow: ${rootStyles.shadow.card}
`

const SalonReservationPage = () => {
    const { currentSalon } = useSelector(state => state.salons)
    const { currentSalonReservations } = useSelector(state => state.reservations)
    const dispatch = useDispatch()
    const { slug } = useParams()
    const { data = { schedule: [], sub_categories: [], photos: [] } } = useDetailsSalonQuery(slug, {
        refetchOnMountOrArgChange: true
    })
    useScrollTop();

    const [shouldIDisplayAskQuestion, setShouldIDisplayAskQuestion] = useState(true)
    const [shouldShowLogin, setShouldShowLogin] = useState(true)
    const [shouldShowSignup, setShouldShowSignup] = useState(true)

    // ! on recupere les reservations qui n'ont pas de "data"
    const checkIfAnyReservationMissedDate = currentSalonReservations?.filter(reservation => !reservation?.date)

    useEffect(() => {
        // ceci permet de recuperer le currentSalon avec ses sub_categories
        dispatch(setCurrentSalon(data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_.isEqual(currentSalon, data)])

    return (
        <MainContent>
            <Div mb={40}>
                <GoBack label={"Retour"} />
            </Div>
            <ReservationTitle>{currentSalon.name}</ReservationTitle>
            <Flex wrap={"wrap"} align={"center"} gap={15}>
                <AdressePane><HiOutlineLocationMarker />  {currentSalon.address}</AdressePane>
                <Dot />
                <Flex align={"flex-end"} minwidth={200}>
                    <Flex align={"center"} gap={2}>
                        <AiOutlineStar size={20} />
                        <SpanTextPane>{currentSalon.stars}</SpanTextPane>
                    </Flex>
                    <Div>
                        <SpanTextPane>({currentSalon.reviews} avis)</SpanTextPane>
                    </Div>
                </Flex>
            </Flex>
            <SelectedServicesPane>
                <PrestationTitle>1. Prestations sélectionnées</PrestationTitle>
                <ReservationsPane>
                    <ReservationFeature />
                </ReservationsPane>
            </SelectedServicesPane>

            {
                // !ici on affiche l'authentification si toute les reservations ont une date
                (currentSalonReservations.length && !checkIfAnyReservationMissedDate.length) ? <AuthenticationPane>


                    {
                        !shouldIDisplayAskQuestion && <>
                            {
                                shouldShowLogin ? <FadeInSpring>
                                    <SignInAndRedirectToReservation />
                                </FadeInSpring> : shouldShowSignup && <FadeInSpring>
                                    <SignUpAndRedirectToReservation />
                                </FadeInSpring>
                            }
                        </>
                    }



                    {
                        shouldIDisplayAskQuestion ? <>
                            {/* <AuthTitlePane>Vous avez déjà un compte sur Kiliya?</AuthTitlePane> */}
                            <Div mt={20} mb={20}>
                                <Button
                                    onClick={() => {
                                        setShouldIDisplayAskQuestion(false)
                                        setShouldShowLogin(true)
                                        setShouldShowSignup(false)
                                        return
                                    }}
                                    width={100}
                                    letterSpacing={3}
                                    fontSize={rootStyles.fontSize.sm}
                                    fontWeight={600}
                                    textTransform="uppercase"
                                    paddingVertical={rootStyles.padding.md}
                                    borderWidth={2}
                                    borderStyle="solid"
                                    borderColor={rootStyles.colors.action}
                                    borderRadius={rootStyles.borderRadius.xl}
                                    backgroundColor={rootStyles.colors.action}
                                    color={rootStyles.colors.primary}
                                    height={20}
                                >
                                    Se connecter
                                </Button>
                            </Div>
                            <Div mt={40} mb={40}>
                                <LinePane>
                                    <TextPane>ou</TextPane>
                                </LinePane>
                            </Div>
                            <AuthTitlePane>Nouveau sur Kiliya?</AuthTitlePane>
                            <Div mt={20} mb={20}>
                                <Button
                                    onClick={() => {
                                        setShouldIDisplayAskQuestion(false)
                                        setShouldShowSignup(true)
                                        setShouldShowLogin(false)
                                        return
                                    }}
                                    width={100}
                                    letterSpacing={3}
                                    fontSize={rootStyles.fontSize.sm}
                                    fontWeight={600}
                                    textTransform="uppercase"
                                    paddingVertical={rootStyles.padding.md}
                                    borderWidth={2}
                                    borderStyle="solid"
                                    borderColor={rootStyles.colors.action}
                                    borderRadius={rootStyles.borderRadius.xl}
                                    backgroundColor={rootStyles.colors.primary}
                                    color={rootStyles.colors.action}
                                    height={20}
                                >
                                    Créer un compte
                                </Button>
                            </Div>
                        </> : <Div>
                            {
                                !shouldIDisplayAskQuestion && <>
                                    <Div mt={40} mb={40}>
                                        <LinePane>
                                            <TextPane>ou</TextPane>
                                        </LinePane>
                                    </Div>
                                    {
                                        shouldShowLogin ? <Button
                                            onClick={() => {
                                                setShouldIDisplayAskQuestion(false)
                                                setShouldShowSignup(true)
                                                setShouldShowLogin(false)
                                                return
                                            }}
                                            width={100}
                                            letterSpacing={3}
                                            fontSize={rootStyles.fontSize.sm}
                                            fontWeight={600}
                                            textTransform="uppercase"
                                            paddingVertical={rootStyles.padding.md}
                                            borderWidth={2}
                                            borderStyle="solid"
                                            borderColor={rootStyles.colors.action}
                                            borderRadius={rootStyles.borderRadius.xl}
                                            backgroundColor={rootStyles.colors.primary}
                                            color={rootStyles.colors.action}
                                                height={20}
                                            >
                                                Créer un compte
                                            </Button> :
                                                <Button
                                                    onClick={() => {
                                                        setShouldIDisplayAskQuestion(false)
                                                        setShouldShowLogin(true)
                                                        setShouldShowSignup(false)
                                                        return
                                                    }}
                                                    width={100}
                                                    letterSpacing={3}
                                                    fontSize={rootStyles.fontSize.sm}
                                                    fontWeight={600}
                                                    textTransform="uppercase"
                                                    paddingVertical={rootStyles.padding.md}
                                                    borderWidth={2}
                                                    borderStyle="solid"
                                                    borderColor={rootStyles.colors.action}
                                                    borderRadius={rootStyles.borderRadius.xl}
                                                    backgroundColor={rootStyles.colors.primary}
                                                color={rootStyles.colors.action}
                                                    height={20}
                                                >
                                                    Se connecter
                                                </Button>

                                    }
                                </>
                            }
                        </Div>
                    }
                </AuthenticationPane> : null
            }

        </MainContent>
    )
}

export default SalonReservationPage