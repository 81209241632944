import { kiliyaApi } from '../../../../redux/rtkquery';
import { MAKE_NEW_RESERVATION_ENDPOINT } from '../../../../routes/api/endpoints';

const reservationApi = kiliyaApi.injectEndpoints({
    endpoints: (builder) => ({
        makeNewResersation: builder.mutation({
            query: (reservation) => ({
                method: 'POST',
                url: MAKE_NEW_RESERVATION_ENDPOINT,
                body: reservation
            })
        })
    })
})

export const { useMakeNewResersationMutation } = reservationApi