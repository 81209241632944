import { kiliyaApi } from "../../../../redux/rtkquery";
import { CATEGORIES_ENPOINT } from "../../../../routes/api/endpoints";

const homeApi = kiliyaApi.injectEndpoints({
    endpoints: (builder) => ({
        categories: builder.query({
            query: () => CATEGORIES_ENPOINT,
            providesTags: ['categories'],
            keepUnusedDataFor: 5000
            // transformResponse: (data) => {
            //     console.log(data);
            //     return { data };
            // }
        }),

    })
})

export const { useCategoriesQuery } = homeApi;