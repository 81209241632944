import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AUTH_PAGE, SALONS_PAGE, SALON_RESERVATION_PAGE } from '../../../../routes/navigation_routes'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from '../../../../redux/features/userslice'
import toast from 'react-hot-toast'
import { errorMsg } from '../../../../helpers/errorMsg'
import { SignupProvider } from '../../../../features/signup/SignupProvider'
import SignupForm from '../../../../components/Signup/SignupForm'
import { welcomeSignup } from '../../../../utils/welcomeSignup'
import { useSignupMutation } from '../../../../features/auth/api/mutations'

const SignUpAndRedirectToReservation = () => {
    const [signupMutation, { isLoading }] = useSignupMutation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currentSalon } = useSelector(state => state.salons)

    console.log({ currentSalon });


    const onSubmit = (data) => {
        signupMutation({ user: data })
            .unwrap()
            .then(res => {
                console.log({ res });

            dispatch(setCurrentUser(res))
                toast.success(`Bienvenue ${res.user.first_name}`, welcomeSignup);
                return navigate(AUTH_PAGE + currentSalon.slug + "/" + SALON_RESERVATION_PAGE, { replace: true })
        }).catch(err => {
            errorMsg(err.data.message)
        })
    }

    return (
        <SignupProvider>
            <SignupForm isLoading={isLoading} onSignup={onSubmit} />
        </SignupProvider>
    )
}

export default SignUpAndRedirectToReservation