import React from 'react'
import { useRouteError } from 'react-router-dom'
import styled from 'styled-components'
import { TbFaceIdError } from "react-icons/tb";
import Logo from './Logo';


const ErrorPane = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
`

const ErrorBoundary = () => {
    const error = useRouteError()
    console.log({ error });
    return (
        <ErrorPane>
            <TbFaceIdError size={90} color="red" />
            <div style={{ margin: "20px 0" }}>
                <p style={{ fontSize: 20, color: "red" }}>Une erreur est survenue</p>
            </div>
            <Logo />
        </ErrorPane>
    )
}

export default ErrorBoundary