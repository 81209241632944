import { createSlice, current } from "@reduxjs/toolkit";
import { getCurrentWeekDays } from "../../utils/currentWeekDays";
import { getPreviousWeekDays } from "../../utils/getPreviousWeekDays";
import { getNextWeekDays } from "../../utils/getNextWeekDays";

const reservationSlice = createSlice({
    name: "reservations",
    initialState: {
        salonsWithReservations: [], // contient tous les paniers de reservations {idSalon: number, reservations: Reservation[]}
        reservations: [],
        currentSalonReservations: [] // chaque salon a son panier de reservations, on le met a jour a chaque fois qu'on est sur la page d'un salon Reservation[]
    },
    reducers: {
        // ! cette methode sera appele a chaque fois qu'on est sur la page d'un salon(SalonDetailsPage)
        setReservationsForCurrentSalon: (state, { payload }) => {
            let reservationsForCurrentSalon = []
            // !check if currentSalon has reservations
            const checkIfIdSalonExistsInsalonsWithReservations = state.salonsWithReservations.filter(reservation => reservation.idSalon === payload.idSalon)
            if (checkIfIdSalonExistsInsalonsWithReservations.length) {
                reservationsForCurrentSalon = checkIfIdSalonExistsInsalonsWithReservations[0].reservations
                return {
                    ...state,
                    currentSalonReservations: reservationsForCurrentSalon
                }
            }
            return {
                ...state,
                currentSalonReservations: []
            }
        },
        addReservation: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const checkIfIdSalonExistsInsalonsWithReservations = copySalonsWithReservations.find(reservation => reservation.idSalon === payload.idSalon)
            // ! si le salon existe deja sur la listes des salons qui on une ou des reservations, on append la nouvelles reservation sur "reservations" du salon
            if (checkIfIdSalonExistsInsalonsWithReservations) {
                const reservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
                const newReservations = [...copySalonsWithReservations[reservationIndex].reservations, payload.reservation]
                copySalonsWithReservations[reservationIndex] = { idSalon: payload.idSalon, reservations: newReservations }
                return {
                    ...state,
                    salonsWithReservations: [...copySalonsWithReservations],
                    currentSalonReservations: newReservations
                }
            }

            return {
                ...state,
                reservations: [...state.reservations, payload],
                salonsWithReservations: [...state.salonsWithReservations, { idSalon: payload.idSalon, reservations: [payload.reservation] }],
                currentSalonReservations: [payload.reservation]
            }
        },
        addEmployeToReservation: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            // ! lets find the index of the currentSalon in salonsWithReservations
            const currentSalonReservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
            let copyCurrentSalonsReservations = [...state.currentSalonReservations]
            // !find the selected reservation to add "employe" property to the current reservation
            let reservation = copyCurrentSalonsReservations.find(reservation => reservation.id === payload.reservationLineId)
            const reservationIndex = copyCurrentSalonsReservations.findIndex(reservation => reservation.id === payload.reservationLineId)

            const working_hours = payload.selectedEmployee.working_hours

            const format_days = working_hours.map((day) => {
                let weekDay = day.day
                // ! Les date du Lundi au Dimanche de l'employe correspondent a la semaine en cours
                let date = getCurrentWeekDays().filter(date => date.day.toLowerCase() === day.day.toLowerCase())[0].date
                const available_hours = []

                if (day.enable) {
                    let startTimeInMinutes = new Date(day.start).getHours() * 60 + new Date(day.start).getMinutes();
                    let endTimeInMinutes = new Date(day.end).getHours() * 60 + new Date(day.start).getMinutes();

                    const TIME_INTERVAL = 30
                    let nextTimeInMin = startTimeInMinutes
                    while (nextTimeInMin < endTimeInMinutes) {
                        let nextTimeInHour = Math.floor((nextTimeInMin) / 60)
                        let nextTimeInMinute = nextTimeInMin % 60

                        nextTimeInMinute = nextTimeInMinute <= 9 ? `0${nextTimeInMinute}` : nextTimeInMinute
                        available_hours.push(nextTimeInHour + ":" + nextTimeInMinute)

                        nextTimeInMin += TIME_INTERVAL
                    }
                }


                return {
                    weekDay,
                    date,
                    available_hours
                }
            })


            //! add the employe to the current reservation
            const reservationWithEmploye = {
                ...reservation,
                employe: payload.employe,
                employeFormaDays: format_days
            }
            copyCurrentSalonsReservations[reservationIndex] = reservationWithEmploye 

            // copySalonsWithReservations[currentSalonReservationIndex] = { idSalon: payload.idSalon, reservations: reservationWithEmploye }
            copySalonsWithReservations[currentSalonReservationIndex] = { idSalon: payload.idSalon, reservations: copyCurrentSalonsReservations }
            // copySalonsWithReservations = []

            // console.log('current ', payload, copyCurrentSalonsReservations, currentSalonReservationIndex);

            return {
                ...state,
                salonsWithReservations: copySalonsWithReservations,
                currentSalonReservations: copyCurrentSalonsReservations
            }
        },
        deleteReservation: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const currentSalonReservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
            //! create reservation copy for immutability
            const copyCurrentSalonsReservations = [...state.currentSalonReservations]
            //!find the selected reservation to delete
            const reservationIndex = state.currentSalonReservations.findIndex(reservation => reservation.id === payload.reservationLineId)
            //! delete the selected reservation
            copyCurrentSalonsReservations.splice(reservationIndex, 1)
            copySalonsWithReservations[currentSalonReservationIndex] = { idSalon: payload.idSalon, reservations: copyCurrentSalonsReservations }
            return {
                ...state,
                currentSalonReservations: [...copyCurrentSalonsReservations],
                salonsWithReservations: copySalonsWithReservations
            }
        },
        addDateToReservation: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const currentSalonReservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
            //! create reservation copy for immutability
            const copyCurrentSalonsReservations = [...state.currentSalonReservations]
            //!find the selected reservation to add "date" property to the current reservation
            let reservation = state.currentSalonReservations.find(reservation => reservation.id === payload.reservationLineId)
            const reservationIndex = state.currentSalonReservations.findIndex(reservation => reservation.id === payload.reservationLineId)
            //! add the date to the current reservation
            const reservationWithDate = {
                ...reservation,
                date: payload.date
            }
            copyCurrentSalonsReservations[reservationIndex] = reservationWithDate
            copySalonsWithReservations[currentSalonReservationIndex] = { idSalon: payload.idSalon, reservations: copyCurrentSalonsReservations }
            return {
                ...state,
                currentSalonReservations: [...copyCurrentSalonsReservations],
                salonsWithReservations: copySalonsWithReservations
            }
        },
        resetCurrentSalonReservations: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const newSalonsWithReservations = copySalonsWithReservations.filter(reservations => reservations.idSalon !== payload.idSalon);
            return {
                ...state,
                currentSalonReservations: [],
                salonsWithReservations: newSalonsWithReservations
            }
        },
        setCalendarPreviousWeek: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const copyCurrentSalonsReservations = [...state.currentSalonReservations]
            // ! lets find the index of the currentSalon in salonsWithReservations
            const currentSalonReservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
            // !find the selected reservation 
            let reservation = copyCurrentSalonsReservations.find(reservation => reservation.id === payload.reservationId)
            const reservationIndex = copyCurrentSalonsReservations.findIndex(reservation => reservation.id === payload.reservationId)

            const newEmployeFormatDays = reservation.employeFormaDays.map(day => ({
                ...day,
                date: getPreviousWeekDays(new Date(payload.currentWeekFirstDay)).filter(previousDay => previousDay.day.toLowerCase() === day.weekDay.toLowerCase())[0].date
            }))

            reservation = { ...reservation, employeFormaDays: newEmployeFormatDays }

            copyCurrentSalonsReservations[reservationIndex] = reservation
            copySalonsWithReservations[currentSalonReservationIndex] = { idSalon: payload.idSalon, reservations: copyCurrentSalonsReservations }

            return {
                ...state,
                currentSalonReservations: copyCurrentSalonsReservations,
                salonsWithReservations: copySalonsWithReservations
            }
        },
        setCalendarNextWeek: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const copyCurrentSalonsReservations = [...state.currentSalonReservations]
            // ! lets find the index of the currentSalon in salonsWithReservations
            const currentSalonReservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
            // !find the selected reservation 
            let reservation = copyCurrentSalonsReservations.find(reservation => reservation.id === payload.reservationId)
            const reservationIndex = copyCurrentSalonsReservations.findIndex(reservation => reservation.id === payload.reservationId)

            const newEmployeFormatDays = reservation.employeFormaDays.map(day => ({
                ...day,
                date: getNextWeekDays(new Date(payload.currentWeekFirstDay)).filter(previousDay => previousDay.day.toLowerCase() === day.weekDay.toLowerCase())[0].date
            }))

            reservation = { ...reservation, employeFormaDays: newEmployeFormatDays }

            copyCurrentSalonsReservations[reservationIndex] = reservation
            copySalonsWithReservations[currentSalonReservationIndex] = { idSalon: payload.idSalon, reservations: copyCurrentSalonsReservations }

            return {
                ...state,
                currentSalonReservations: copyCurrentSalonsReservations,
                salonsWithReservations: copySalonsWithReservations
            }
        },
        setCalendarSelectedWeek: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const copyCurrentSalonsReservations = [...state.currentSalonReservations]
            // ! lets find the index of the currentSalon in salonsWithReservations
            const currentSalonReservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
            // !find the selected reservation 
            let reservation = copyCurrentSalonsReservations.find(reservation => reservation.id === payload.reservationId)
            const reservationIndex = copyCurrentSalonsReservations.findIndex(reservation => reservation.id === payload.reservationId)

            const newEmployeFormatDays = reservation?.employeFormaDays?.map(day => ({
                ...day,
                date: getCurrentWeekDays(new Date(payload.firstDay)).filter(previousDay => previousDay.day.toLowerCase() === day.weekDay.toLowerCase())[0].date
            }))

            reservation = { ...reservation, employeFormaDays: newEmployeFormatDays }

            copyCurrentSalonsReservations[reservationIndex] = reservation
            copySalonsWithReservations[currentSalonReservationIndex] = { idSalon: payload.idSalon, reservations: copyCurrentSalonsReservations }

            return {
                ...state,
                currentSalonReservations: copyCurrentSalonsReservations,
                salonsWithReservations: copySalonsWithReservations
            }
        }
    }
})

export const {
    addReservation,
    addEmployeToReservation,
    deleteReservation,
    addDateToReservation,
    setReservationsForCurrentSalon,
    resetCurrentSalonReservations,
    setCalendarPreviousWeek,
    setCalendarNextWeek,
    setCalendarSelectedWeek
} = reservationSlice.actions
export default reservationSlice.reducer