import React from 'react'
import { Placeholder } from 'semantic-ui-react'
import Flex from './Flex'
import Div from './Div'

const PlaceholderLoader = () => (
    <Placeholder fluid={true}>
        <Placeholder.Header image>
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
            <Placeholder.Line length='short' />
            <Placeholder.Line length='short' />
        </Placeholder.Header>
        <Placeholder.Paragraph>
            <Flex justify={"space-between"} align={"center"} gap={20}>
                <Flex flex={1}>
                    <Placeholder.Line length='short' />
                    <Placeholder.Line length='short' />
                </Flex>
                <Flex flex={1}>
                    <Placeholder.Line length='short' />
                    <Placeholder.Line length='short' />
                </Flex>
            </Flex>
            <Div mt={10}>
                <Flex justify={"space-between"} align={"center"} gap={20}>
                    <Flex flex={1}>
                        <Placeholder.Line length='short' />
                        <Placeholder.Line length='short' />
                    </Flex>
                    <Flex flex={1}>
                        <Placeholder.Line length='short' />
                        <Placeholder.Line length='short' />
                    </Flex>
                </Flex>
            </Div>
        </Placeholder.Paragraph>
    </Placeholder>
)

export default PlaceholderLoader