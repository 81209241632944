import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { privateRoutes, publicRoutes } from './routes/navigation_paths'
import { useSelector } from 'react-redux'

const Roots = () => {
  const { currentUser } = useSelector(state => state.user)
  // render public or private routes based on currentUser
  const router = !currentUser?.access_token ? createBrowserRouter([...publicRoutes]) : createBrowserRouter([...privateRoutes])

  return (
    <RouterProvider router={router} />
  )
}

export default Roots