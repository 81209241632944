import React from 'react'
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"

import { LabelPane } from '../Input'
import "./customInput.css"

const CustomPhoneNumber = ({ label, control, name }) => {
  return (
    <div>
      <LabelPane>{label}</LabelPane>
      <PhoneInputWithCountry
        className='custom-input'
        defaultCountry='SN'
        name={name}
        control={control}
        rules={{ required: true }}
      />
    </div>
  )
}

export default CustomPhoneNumber