import React from "react";
import "./dropdown.css";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdArrowDropUp } from "react-icons/md";
import { useSelector } from "react-redux";
import Flex from "../../components/Flex";
import { BiUserCircle } from "react-icons/bi";

const DropDown = ({ showDropdown, setShowDropdown, children }) => {
    const { currentUser } = useSelector(state => state.user);
    return (
        <div className="dropdown-wrapper">
            <button
                onClick={setShowDropdown}
                className="trigger-button"
            >
                {!showDropdown ? (
                    <div>
                        <Flex align={"center"}>
                            <Flex direction="column" gap={0} align={"center"}>
                                <BiUserCircle size={25} style={{ marginTop: '10px' }} />
                                <RiArrowDropDownLine
                                    style={{ marginTop: "-10px" }}
                                    size={20}
                                // color="#069a77"
                                />
                            </Flex>
                            <span
                                // style={{ color: rootStyles.color.secondary }}
                                className="fw-bold"
                            >
                                {`${currentUser?.user.first_name.charAt(
                                    0
                                )}${currentUser?.user.last_name.charAt(0)}`}
                            </span>
                        </Flex>
                    </div>
                ) : (
                    <Flex align={"center"}>
                        <Flex direction="column" gap={0} align={"center"}>
                            <BiUserCircle size={25} style={{ marginTop: '10px' }} />
                            <MdArrowDropUp
                                style={{ marginTop: "-10px" }}
                                size={20}
                            />
                        </Flex>
                        <span
                            // style={{ color: rootStyles.color.secondary }}
                            className="fw-bold"
                        >
                            {`${currentUser?.user.first_name.charAt(
                                0
                            )}${currentUser?.user.last_name.charAt(0)}`}
                        </span>
                    </Flex>
                )}
            </button>
            <ul className={showDropdown ? "active drop-down" : "drop-down"}>
                {children}
            </ul>
        </div>
    );
};

export default DropDown;
