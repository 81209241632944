import React, { useState } from 'react'
import { IoMdAdd } from "react-icons/io"

import { rootStyles } from '../../style/shared';
import Flex from '../../components/Flex';
import Button from '../../components/Button';
import ReservationCardItem, { DeleteBtnPane } from './components/ReservationCardItem';
import AddNewReservation from './components/AddNewReservation';
import Div from '../../components/Div';
import { Message } from 'semantic-ui-react';
import useTotalReservation from '../../hooks/useTotalReservation';
import ReservationsList from './components/ReservationsList';

const ReservationFeature = () => {
    const [showAddNewReservation, setShowAddNewReservation] = useState()
    const { totalReservation } = useTotalReservation()

    return (
        <div>
            <ReservationsList
                reservationCardItem={({ reservation }) => <ReservationCardItem key={reservation.id} reservation={reservation} />}
            />
            <Div mt={40}>
                <Flex justify={"space-between"} align={"center"} wrap={"wrap-reverse"} gap={10}>
                    {
                        !showAddNewReservation && <Button
                            onClick={() => setShowAddNewReservation(true)}
                            letterSpacing={1}
                            fontSize={rootStyles.fontSize.sm}
                            paddingVertical={10}
                            paddingHorizontal={20}
                            fontWeight={500}
                            textTransform="normal"
                            borderWidth={2}
                            borderStyle="solid"
                            borderColor={rootStyles.colors.action}
                            borderRadius={rootStyles.borderRadius.sm}
                            backgroundColor={rootStyles.colors.action}
                            color={rootStyles.colors.primary}
                        // width={100}
                        >
                            <Flex align={"center"} justify={"center"}>
                                <IoMdAdd size={25} />
                                <span>Ajouter une réservation</span>
                                {/* <span>Ajouter une réservation à la suite</span> */}
                            </Flex>
                        </Button>
                    }
                    <Message
                        header={`Total: ${totalReservation} FCFA`}
                    />
                </Flex>
            </Div>
            <Div mt={50}>
                {
                    showAddNewReservation && <>
                        <Flex justify={"flex-end"}>
                            <DeleteBtnPane
                                onClick={() => setShowAddNewReservation(false)}>
                                Supprimer
                            </DeleteBtnPane>
                        </Flex>
                        <AddNewReservation setShowAddNewReservation={setShowAddNewReservation} />
                    </>
                }
            </Div>
        </div>
    )
}

export default ReservationFeature