import React, { useEffect } from 'react'
import { AiOutlineStar } from 'react-icons/ai'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import _ from "lodash"

import MainContent from '../../../components/MainContent'
import { AdressePane, PrestationTitle, ReservationTitle, ReservationsPane, SelectedServicesPane, SpanTextPane } from './SalonReservationPage'
import Flex from '../../../components/Flex'
import Dot from '../../../components/Dot'
import Div from '../../../components/Div'
import ReservationFeature from '../../../features/reservation/ReservationFeature'
import Button from '../../../components/Button'
import { rootStyles } from '../../../style/shared'
import GoBack from '../../../components/GoBack'
import { useNavigate, useParams } from 'react-router-dom'
import { AUTH_PAGE, RESERVATIONS_PAGE } from '../../../routes/navigation_routes'
import { useMakeNewResersationMutation } from '../../../features/reservation/api/mutations'
import { successMsg } from '../../../helpers/successMsg';
import { errorMsg } from '../../../helpers/errorMsg'
import useTotalReservation from '../../../hooks/useTotalReservation'
import { resetCurrentSalonReservations } from '../../../redux/features/reservationSlice'
import { useDetailsSalonQuery } from '../api/queries'
import { setCurrentSalon } from '../../../redux/features/salonSlice'
import useScrollTop from '../../../utils/scrollToTop'

const SalonReservationUserConnected = () => {
    const { currentSalon } = useSelector(state => state.salons)
    const { currentSalonReservations } = useSelector(state => state.reservations)
    const { currentUser } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const { slug } = useParams()
    const { data = { schedule: [], sub_categories: [], photos: [] }, } = useDetailsSalonQuery(slug, {
        refetchOnMountOrArgChange: true
    })
    useScrollTop();

    const [makeNewReservation, { isLoading }] = useMakeNewResersationMutation()
    const { totalReservation } = useTotalReservation()
    const navigate = useNavigate()

    const canUserSubmit = currentSalonReservations.every((reservation) => (reservation?.employe && reservation?.date))

    const makeNewReservationFunc = () => {
        const appointmentServices = currentSalonReservations.map(reservation => {
            return {
                service_id: reservation.reservation.id,
                employee_id: reservation.employe?.value,
                start: reservation.date?.fullDate + `T${reservation?.date?.hour}`
            }
        })

        const appointment = {
            salon_id: currentSalon.id,
            client_id: currentUser.user.id,
            date: currentSalonReservations[0]?.date?.fullDate + `T${currentSalonReservations[0]?.date?.hour}`,
            price: totalReservation,
            comment: "Kiliya",
            services: appointmentServices,
        }

        makeNewReservation({ appointment }).unwrap()
            .then(() => {
                successMsg("Nouvelle réservation effectuée avec succès")
                dispatch(resetCurrentSalonReservations({ idSalon: currentSalon.id }))
                navigate(AUTH_PAGE + RESERVATIONS_PAGE)
            })
            .catch((err) => {
                // console.log({ err });
                errorMsg(err.data.message)
            })
    }

    useEffect(() => {
        dispatch(setCurrentSalon(data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_.isEqual(currentSalon, data)])

    return (
        <MainContent>
            <Div mb={40}>
                <GoBack label={"Retour"} navigateTo={() => navigate(AUTH_PAGE + slug)} />
            </Div>
            <ReservationTitle>{currentSalon.name}</ReservationTitle>
            <Flex align={"center"} gap={15} wrap={"wrap"}>
                <Flex minwidth={300}>
                    <AdressePane><HiOutlineLocationMarker />  {currentSalon.address}</AdressePane>
                </Flex>
                <Dot />
                <Flex align={"flex-end"}>
                    <Flex align={"center"} gap={2}>
                        <AiOutlineStar size={20} />
                        <SpanTextPane>{currentSalon.stars}</SpanTextPane>
                    </Flex>
                    <Div>
                        <SpanTextPane>({currentSalon.reviews} avis)</SpanTextPane>
                    </Div>
                </Flex>
            </Flex>
            <SelectedServicesPane>
                <PrestationTitle>1. Prestations sélectionnées</PrestationTitle>
                <ReservationsPane>
                    <ReservationFeature />
                </ReservationsPane>

                {
                    (!!currentSalonReservations.length && canUserSubmit) && <Div mt={40}>
                        <Button
                            disabled={isLoading}
                            onClick={makeNewReservationFunc}
                            width={100}
                            letterSpacing={3}
                            fontSize={rootStyles.fontSize.sm}
                            fontWeight={600}
                            textTransform="uppercase"
                            paddingVertical={rootStyles.padding.md}
                            borderWidth={2}
                            borderStyle="solid"
                            borderColor={rootStyles.colors.action}
                            borderRadius={rootStyles.borderRadius.xl}
                            backgroundColor={rootStyles.colors.primary}
                            color={rootStyles.colors.action}
                            height={20}
                        >
                            {
                                isLoading ? "Réservation en cours..." : "Réserver"
                            }
                        </Button>
                    </Div>
                }
            </SelectedServicesPane>
        </MainContent>
    )
}

export default SalonReservationUserConnected