import React, { Suspense } from 'react'
import Header from './header/Header'
import Navbar from './navbar/Navbar'
import { Outlet } from 'react-router-dom'
import Footer from './footer/Footer'
import { MainContaintPane } from './AppShell'
import PageLoading from '../features/reservation/components/PageLoading'

const AuthAppSell = () => {
    return <Suspense fallback={<PageLoading />} >
        <Header />
        <Navbar />
        <MainContaintPane>
            <Outlet />
        </MainContaintPane>
        <Footer />
    </Suspense>
}

export default AuthAppSell