import React from 'react'
import styled from 'styled-components'

const ButtonPane = styled.button`
    all: unset;
    padding: ${({ paddingvertical, paddinghorizontal }) => `${paddingvertical}px ${paddinghorizontal}px`};
    font-size: ${({ fontsize }) => `${fontsize ?? 12}px`};
    font-weight: ${({ fontweight }) => fontweight ? `${fontweight}` : 400};
    border-width: ${({ borderwidth }) => borderwidth ? `${borderwidth}px` : 0};
    border-style: ${({ borderstyle }) => borderstyle};
    border-color: ${({ bordercolor }) => bordercolor};
    border-radius: ${({ borderradius }) => borderradius ? `${borderradius}px` : 0};
    color: ${({ color }) => color};
    background-color: ${({ backgroundcolor }) => backgroundcolor};
    transition: all ease-in-out 300ms;
    text-transform: ${({ texttransform }) => texttransform};
    letter-spacing: ${({ letterspacing }) => `${letterspacing}px`};
    height: ${({ height }) => `${height}px`};
    width: ${({ width }) => `${width}%`};
    text-align: center;
    opacity: ${({ disabled }) => disabled ? .5 : 1};
    cursor: ${({ disabled }) => disabled ? `default !important` : `pointer`};

    &:active{
    transform: scale(.98);
};

    &:hover{
    background-color: ${({ hoverbackgroundcolor }) => `${hoverbackgroundcolor}`};
    color: ${({ hovercolor }) => `${hovercolor}`};
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

@media screen and (max-width: 600px){
    font-size: ${({ responsive }) => responsive ? `12px` : ""};
    padding: ${({ responsive }) => responsive ? `5px 15px` : ""};
}
`

const Button = ({
    disabled,
    type,
    width,
    paddingVertical = 8,
    paddingHorizontal = 0,
    fontSize,
    fontWeight,
    borderWidth,
    borderStyle,
    borderColor,
    borderRadius,
    color = "#000",
    backgroundColor = "#FFFF",
    hoverBackgroundColor,
    hoverColor,
    textTransform,
    letterSpacing,
    children,
    height,
    onClick,
    responsive
}) => {
    return (
        <ButtonPane
            disabled={disabled}
            responsive={responsive}
            type={type ?? "submit"}
            width={width}
            onClick={onClick}
            fontsize={fontSize}
            fontweight={fontWeight}
            borderwidth={borderWidth}
            borderstyle={borderStyle}
            bordercolor={borderColor}
            borderradius={borderRadius}
            color={color}
            backgroundcolor={backgroundColor}
            hoverbackgroundcolor={hoverBackgroundColor}
            hovercolor={hoverColor}
            paddingvertical={paddingVertical}
            paddinghorizontal={paddingHorizontal}
            texttransform={textTransform}
            letterspacing={letterSpacing}
            height={height}
        >
            {children}
        </ButtonPane>
    )
}

export default Button