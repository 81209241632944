import React from 'react'
import { useSelector } from 'react-redux'
import SimpleEmptyText from '../../../components/Empty/SimpleEmptyText'

const ReservationsList = ({ reservationCardItem: ReservationCardItem }) => {
    const { currentSalonReservations } = useSelector(state => state.reservations)
    return (
        <div>
            {
                currentSalonReservations?.length ?
                    currentSalonReservations?.map(reservation => ReservationCardItem({ reservation })) :
                    <SimpleEmptyText message={"Pas de réservations"} />
            }
        </div>
    )
}

export default ReservationsList