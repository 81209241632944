import React from 'react'
import Logo from '../../../components/Logo'
// import Div from '../../../components/Div'
import styled from 'styled-components'

const AnimatedPane = styled.div`
    animation-name: breath-animation;
    animation-duration: 8s;
    animation-iteration-count: infinite;

    @keyframes breath-animation {
    0% { transform: scale(.5) }
    30% { transform: scale(1) }
    40% { transform: scale(.5) }
    100% { transform: scale(.5) }
    }
`

const PageLoading = () => {
    return (
        <div style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <AnimatedPane>
                <Logo />
            </AnimatedPane>
        </div>
    )
}

export default PageLoading