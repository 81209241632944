import React from "react"
import styled from "styled-components"
import { rootStyles } from "../style/shared"

const BtnPane = styled.a`
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${rootStyles.colors.action};
    color: ${rootStyles.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:active{
        transform: scale(.9)
    }
`

const SocialMediaLink = ({ children }) => {
    return (
        <BtnPane>
            {children}
        </BtnPane>
    )
}

export default SocialMediaLink