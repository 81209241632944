import { createContext, useCallback, useMemo } from "react";
import { useLogoutMutation } from "../../features/auth/api/mutations";
import { useDispatch } from "react-redux";
import { resetCurrentUser } from "../../redux/features/userslice";

export const AuthenticationContext = createContext()

const AuthenticationProvider = ({ children }) => {
    const [logoutMutation] = useLogoutMutation()
    const dispatch = useDispatch()

    const logout = useCallback((callback) => {
        logoutMutation().unwrap().then(() => {
            dispatch(resetCurrentUser())
        }).finally(() => {
            callback()
        })
    }, [dispatch, logoutMutation])

    const value = useMemo(() => ({
        logout
    }), [logout])

    return <AuthenticationContext.Provider value={value}>
        {children}
    </AuthenticationContext.Provider>
}

export default AuthenticationProvider