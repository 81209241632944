import React from "react"
import styled from "styled-components"
import { rootStyles } from "../style/shared"

const ContainerPane = styled.div`
    min-width: 250px;
`
const FooterTitlePane = styled.h3`
    text-transform: uppercase;
    font-size: ${rootStyles.fontSize.sm}px;
    font-weight: 700;
    color: ${rootStyles.colors.inputPlaceHolderColor};
    margin-block-start: 0px;
    margin-block-end: 10px;
`
const BodyPane = styled.div`
    margin-top: 0px;
`

const FooterCardItem = ({ title, children }) => {
    return (
        <ContainerPane>
            <FooterTitlePane>{title}</FooterTitlePane>
            <BodyPane>
                {children}
            </BodyPane>
        </ContainerPane>
    )
}

export default FooterCardItem