import React from 'react'
import Div from '../../../components/Div'
import SalonServiceCardItem from '../../../pages/salons/ui/components/SalonServiceCardItem'
import { addReservation } from '../../../redux/features/reservationSlice'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { rootStyles } from '../../../style/shared'

const TitlePane = styled.h4`
    font-size: ${rootStyles.fontSize.md}px;
    font-weight: 500;
    text-transform: uppercase;

    @media (max-width: 600px){
        font-size: ${rootStyles.fontSize.sm}px;
    }
`
const CardContainerane = styled.div`
    background-color: ${rootStyles.colors.primary};
    box-shadow: 0 4px 8px -2px rgba(26, 27, 31, 0.3);
    padding: ${rootStyles.padding.md}px;
    border-radius: 8px;
    margin-bottom: 30px
`

const AddNewReservation = ({ setShowAddNewReservation }) => {
    const { currentSalon } = useSelector(state => state.salons)
    const dispatch = useDispatch()

    console.log({ currentSalon });


    return (
        <div>
            {
                currentSalon?.sub_categories?.length ? currentSalon?.sub_categories.map(sub_categorie => {
                    return <Div key={sub_categorie.id} mt={10}>
                        {/* <AccordionContainer> */}
                        <TitlePane>{sub_categorie.name}</TitlePane>
                        {/* <AccordionItem accordionTitle={sub_categorie.name}> */}
                        {
                            <CardContainerane>
                                {
                                    sub_categorie.services.length ? sub_categorie.services.map(service => {
                                        return <SalonServiceCardItem
                                            key={service.id}
                                            name={service.name}
                                            description={service.description}
                                            duration={service.duration}
                                            price={service.price}
                                            has_promotion={currentSalon?.has_promotion}
                                            onClickBtn={() => {
                                                // ! start reservation
                                                dispatch(addReservation({
                                                    idSalon: currentSalon.id,
                                                    reservation: {
                                                        id: Math.round(Math.random() * 1000000) + "#",
                                                        reservation: {
                                                            id: service.id,
                                                            name: service.name,
                                                            duration: service.duration,
                                                            price: service.price,
                                                            ...service
                                                        }
                                                    }
                                                }))
                                                return setShowAddNewReservation(false)
                                            }}
                                        />
                                    }) : <p>Pas encore de services disponible</p>
                                }
                            </CardContainerane>
                        }
                        {/* </AccordionItem> */}
                        {/* </AccordionContainer> */}
                    </Div>
                }) : <p>Pas encore de services disponibles</p>
            }
        </div>
    )
}

export default AddNewReservation